<template>
  <div style="width: 100%; background-color: transparent">
    <div style="text-align: right; background-color: #FFFFFF; padding: 10px; width: 99%; margin: 0 auto; border-radius: 5px">
      <span style="float: left; line-height: 40px">定向监测</span>
      <el-button type="primary" style="margin-right: 10px" @click="$router.push('/spider/add')">添加</el-button>
    </div>
    <div style="margin-top: 25px; margin-bottom: 10px; overflow: auto">
      <div :key="item.id" v-for="item in dataList" style="width: 25%; float: left; margin-bottom: 30px" v-show="dataList.length > 0">
        <div style="width: 95%; margin: 0 auto; background-color: #FFFFFF; border-radius: 10px; height: 105px">
          <img src="/bg-internet.png" style="width: 50px; position: absolute; margin-left: 15px; margin-top: -15px">
          <span style="position: absolute; margin-left: 70px; margin-top: 15px">{{ item.siteName }}</span>
          <div style="padding-top: 70px; text-align: right">
            <el-tag v-if="item.enable" style="float: left; margin-left: 15px" type="success" size="mini">已启用</el-tag>
            <el-tag v-else style="float: left; margin-left: 15px" type="info" size="mini">已停用</el-tag>
            <el-tag style="float: left; margin-left: 5px" type="primary" size="mini">{{ item.cronText }}小时/次</el-tag>
<!--            <el-tooltip class="item" effect="dark" content="查看抓取日志" placement="top-start">-->
<!--              <span style="cursor: pointer; background-color: #67C23A; color: #FFF;padding: 5px 10px; border-radius: 5px; margin-right: 5px"><i class="el-icon-tickets"></i></span>-->
<!--            </el-tooltip>-->
            <el-tooltip class="item" effect="dark" content="编辑" placement="top-start">
              <span @click="$router.push('/spider/edit/' + item.id)" style="cursor: pointer; background-color: #2D64B3; color: #FFF;padding: 5px 10px; border-radius: 5px; margin-right: 5px"><i class="el-icon-setting"></i></span>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
              <span @click="delId = item.id, dialogVisible = true" style="cursor: pointer; background-color: #F56C6C; color: #FFF;padding: 5px 10px; border-radius: 5px; margin-right: 15px"><i class="el-icon-delete"></i></span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div style="background-color: #FFFFFF; width: 100%; height: 500px; text-align: center; line-height: 500px; width: 99%; margin: 0 auto" v-show="dataList.length === 0">
        <span style="color: lightgray">暂无监测站点</span>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%">
      <span>确定要删除该监测吗？</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="deleteSpider">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import { fetchAll, del } from '@/api/spider'
export default {
  name: 'index',
  data () {
    return {
      dataList: [],
      dialogVisible: false,
      delId: ''
    }
  },
  methods: {
    async fetch () {
      const res = await fetchAll()
      this.dataList = res.data
    },
    async deleteSpider () {
      const res = await del({ id: this.delId })
      this.dialogVisible = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.fetch()
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>

<style scoped>

</style>
