import request from '@/utils/request'

export function fetchAll () {
  return request({
    url: '/spider/list',
    method: 'POST'
  })
}
export function add (data) {
  return request({
    url: '/spider/add',
    method: 'POST',
    data
  })
}
export function test (data) {
  return request({
    url: '/spider/test',
    method: 'POST',
    data
  })
}
export function gerInfo (data) {
  return request({
    url: '/spider/info',
    method: 'POST',
    data
  })
}
export function update (data) {
  return request({
    url: '/spider/update',
    method: 'POST',
    data
  })
}
export function del (data) {
  return request({
    url: '/spider/delete',
    method: 'DELETE',
    data
  })
}
